const cw = (cw || {});

(($) => {

    function AreaForm(element) {

        const self = this;
        const $form = $(element);
        const recaptchaV3SiteKey = ($form.find('input.recaptcha-v3-site-key').length) ? $form.find('input.recaptcha-v3-site-key').val() : '';
        const recaptchaV3Token = $form.find('input.recaptcha-v3-token');
        const recaptchaV3Action = $form.data('recaptcha-action') ? $form.data('recaptcha-action') : 'area_form';

        this.init = function() {
            self.registerEvents();

            setTimeout(function(){
                self.visibleGrecaptchaBagde();
            },5000);
        };

        this.registerEvents = function() {
            self.recaptchaCheck();
        };

        this.recaptchaCheck = function(event) {
            if(recaptchaV3SiteKey != '') {
                // when form is submit
                $form.submit(function (event) {
                    // we stoped it
                    event.preventDefault();

                    self.grecaptchaExecute();
                });
            }
        };

        this.grecaptchaExecute = function() {
            /* grecaptcha v3: https://developers.google.com/recaptcha/intro */
            // needs for recaptcha ready
            grecaptcha.ready(function() {
                grecaptcha.execute(recaptchaV3SiteKey, {action: recaptchaV3Action}).then(function (token) {
                    // add token to form
                    if (recaptchaV3Token.length) {

                        recaptchaV3Token.val(token);

                        $form.unbind('submit').submit();
                    }
                });
            });
        };

        this.visibleGrecaptchaBagde = function() {
            const grecaptchaBadge = $('.grecaptcha-badge');

            if(grecaptchaBadge.length) {
                grecaptchaBadge.addClass('grecaptcha-badge--visible');
            }
        };

        self.init();
    }

    cw.areaForm = AreaForm;

    $(document).ready(() => {
        /*
        $('form.area-form').each(function(index, element) {
            const $elem = $(element);
            const data = $elem.data('cw.area-form');
            if (!data) {
                $elem.data('cw.area-form', new AreaForm($elem));
            }
        });
         */
    });

})(jQuery);