const cw = (cw || {});

(($) => {

    /**
     * Main function
     */
    function Main() {

        const self = this;

        let headerHeight = $('.nav-leiste .nav-leiste__inner').outerHeight();

        this.init = function () {
            self.initHeaderSticky();

            $(window).resize(() => {
                self.initHeaderSticky();
            });
        };

        this.initHeaderSticky = function() {
            /* fixed falls scroll to top, otherwise static */

            let enableHeaderUp = false;
            let enableHeaderSticky = false;
            let offset = 0;

            $(window).on('scroll', function() {
                let scrollTop = $(window).scrollTop();

                if(scrollTop >= headerHeight + offset) {
                    enableHeaderUp = true;
                } else {
                    enableHeaderUp = false;
                }
                if(scrollTop >= headerHeight + offset) { // >= 1
                    enableHeaderSticky = true;
                } else {
                    enableHeaderSticky = false;
                }

            });

            $(window).on('scroll', function() {
                if (this.oldScroll >= this.scrollY && enableHeaderUp == true) {
                    // scroll up
                    if (enableHeaderSticky == true) {
                        $('.nav-leiste').addClass('header--sticky');
                    }
                    $('.nav-leiste').removeClass('header--up');

                } else if(this.oldScroll < this.scrollY && enableHeaderUp == true) {
                    // scroll down
                    $('.nav-leiste').removeClass('header--sticky');
                    $('.nav-leiste').addClass('header--up');
                } else {
                    $('.nav-leiste').removeClass('header--sticky');
                    $('.nav-leiste').removeClass('header--up');
                }
                this.oldScroll = this.scrollY;
            });
        };

    }

    $(document).ready(() => {
        cw.main = new Main();
        cw.main.init();
    });

})(jQuery);